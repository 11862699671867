import { Flex, Text, Box } from '@chakra-ui/react'

import { useFPTColData } from '@/hooks/useFPTColData'

import { CollectionTable } from './CollectionTable'
import { TokenLoader } from './TokenLoader'
import { IBulkSelectToken, IOnSelectBulkTokens, SelectedToken } from './types'

interface ShadowCollectionProps {
  onClickRetrieve: (selectedToken: SelectedToken) => void
  onClickSend: (selectedToken: SelectedToken) => void
  onClickBulkRetrieve: () => void
  onClickBulkSend: () => void
  onSelectBulkTokens: (selectedToken: IOnSelectBulkTokens) => void

  selectedBulkTokens: IBulkSelectToken | null
}

export const ShadowCollections = ({
  onClickRetrieve,
  onClickSend,
  onClickBulkRetrieve,
  onSelectBulkTokens,
  onClickBulkSend,

  selectedBulkTokens,
}: ShadowCollectionProps) => {
  const { FPTColData, isLoading } = useFPTColData()

  if (isLoading) return <TokenLoader />

  return (
    <>
      <Flex mb="10" justifyContent="space-between" alignItems="flex-end">
        <Text fontSize="xl" fontWeight="bold">
          FPT Collections
        </Text>
      </Flex>
      {FPTColData?.length ? (
        FPTColData?.map((item) => (
          <CollectionTable
            key={item.collectionAddress}
            collectionData={item}
            onClickRetrieve={onClickRetrieve}
            onClickSend={onClickSend}
            onClickBulkRetrieve={onClickBulkRetrieve}
            onSelectBulkTokens={onSelectBulkTokens}
            onClickBulkSend={onClickBulkSend}
            selectedBulkTokens={selectedBulkTokens}
          />
        ))
      ) : (
        <Box marginY="24" textAlign="center">
          <Text fontSize="2xl" color="slate.500" marginBottom="4">
            ¯\_(ツ)_/¯
          </Text>
          <Text color="slate.500" fontSize="lg">
            You don&apos;t have any Shadow Token enabled NFTs
          </Text>
        </Box>
      )}
    </>
  )
}
