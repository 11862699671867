/* eslint-disable react/jsx-props-no-spreading */
import { ChakraProvider } from '@chakra-ui/react'
import { getDefaultWallets, RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import '@rainbow-me/rainbowkit/styles.css'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import Layout from '@/components/layout'
import { customTheme } from '@/theme/chakraTheme'

import { config } from '../../config'
import defaultSEOConfig from '../../next-seo.config'

const { chains, publicClient } = configureChains(
  [config.chain],
  [
    //alchemyProvider({ apiKey: config.alchemyId || '' }),
    publicProvider(),
    jsonRpcProvider({
      rpc: () => ({
        http: config.jsonRPc,
      }),
    }),
  ]
)

const { connectors } = getDefaultWallets({
  appName: 'FoolProof labs',
  projectId: '242336b2b5817498381c743cafaaac0a',
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  const queryClient = new QueryClient()
  return (
    <WagmiConfig config={wagmiConfig}>
      <NextNProgress color="#000" />
      <RainbowKitProvider
        showRecentTransactions={true}
        modalSize="compact"
        chains={chains}
        theme={lightTheme({
          accentColor: '#000',
          accentColorForeground: 'white',
          borderRadius: 'small',
          fontStack: 'system',
          overlayBlur: 'none',
        })}
      >
        <ChakraProvider resetCSS theme={customTheme}>
          <DefaultSeo {...defaultSEOConfig} />
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </QueryClientProvider>
        </ChakraProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export default MyApp
