import {
  Box,
  Flex,
  useDisclosure,
  Alert,
  AlertIcon,
  CloseButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'

import { useIsClient } from '@/hooks/useIsClient'

import Footer from './Footer'
import Header from './Header'
import Sidebar from './SideBar'

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isClient = useIsClient()
  const { isOpen: isVisible, onClose: alertClose } = useDisclosure({ defaultIsOpen: true })

  return (
    <Box transition="0.5s ease-out ease" bg={useColorModeValue('slate.100', 'slate.900')}>
      {isVisible && isClient && (
        <Alert
          zIndex={20}
          colorScheme="brand"
          backdropFilter="blur(64px)"
          status="warning"
          justifyContent="space-between"
          py={1}
        >
          <Flex alignItems="center">
            <AlertIcon boxSize={4} />
            <Text fontSize="sm">
              Make sure you&apos;re on{' '}
              <span color="blue.500">https://dashboard.foolprooflabs.io</span>
            </Text>
          </Flex>
          <CloseButton size="sm" onClick={alertClose} />
        </Alert>
      )}
      <Header onClose={onClose} onOpen={onOpen} isOpen={isOpen} />
      <Box as="main" minH="90vh" w="full">
        <Sidebar onClose={onClose} isOpen={isOpen} />
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout
