import {
  Text,
  Box,
  Flex,
  Skeleton,
  SkeletonText,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'

export const TokenLoader = () => {
  return (
    <>
      <Flex mb="10" justifyContent="space-between" alignItems="flex-end">
        <Text fontSize="xl" fontWeight="semibold">
          FPT Collections
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={6}
        bgColor="brand.900"
        textColor="slate.200"
        w="full"
        roundedTop="md"
      >
        <Flex alignItems="center">
          <Skeleton h="16" w="16" mr="4" rounded="md" />

          <Flex w="100px" flexDir="column" gap={1}>
            <SkeletonText noOfLines={3} spacing="4" w="60%" />
          </Flex>
        </Flex>
        <Box textAlign="right" w="100px">
          <SkeletonText noOfLines={2} spacing="4" />
        </Box>
      </Flex>
      <Box
        // borderColor={colorModeBorder}
        borderWidth={1}
        borderTopWidth={0}
        // bg="white"
        mb={14}
        py={8}
        px={{ sm: 0, md: 12 }}
        w="full"
        // rounded="md"
        roundedBottom="md"
        // bgColor="brand.100"
      >
        {/* <Flex justifyContent="space-between">
          <Text fontWeight="bold">Token</Text>
          <Text fontWeight="bold">Status</Text>
          <Text fontWeight="bold">Action</Text>
        </Flex> */}

        <Table variant="unstyled" colorScheme="brand">
          <Thead>
            <Tr>
              <Th>
                <SkeletonText noOfLines={1} spacing="4" />
              </Th>
              <Th w="100px">
                <SkeletonText noOfLines={1} spacing="4" />
              </Th>
              <Th w="100px">
                <SkeletonText noOfLines={1} spacing="4" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {[...Array(3)].map((_, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <SkeletonText noOfLines={1} spacing="4" w="60%" />
                  </Td>
                  <Td w="100px">
                    <SkeletonText noOfLines={1} spacing="4" w="60%" />
                  </Td>
                  <Td w="100px">
                    <SkeletonText noOfLines={1} spacing="4" w="60%" />
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
    </>
    // <Box p="6" w="full">
    //   <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 5 }} mx="auto" gap={10}>
    //     {[...Array(3)].map((index) => (
    //       <Box
    //         key={index}
    //         role={'group'}
    //         p={3}
    //         maxW={'330px'}
    //         w={'full'}
    //         overflow="hidden"
    //         borderWidth="thin"
    //         rounded={'md'}
    //       >
    //         <Skeleton height="230px" />
    //         <Flex alignItems={'center'} justifyContent="space-between" pt={5}>
    //           <SkeletonText noOfLines={2} spacing="4" w="60%" />
    //           <Skeleton height="30px" w="25%" />
    //         </Flex>
    //       </Box>
    //     ))}
    //   </SimpleGrid>
    // </Box>
  )
}
