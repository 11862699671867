import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Input,
  FormHelperText,
  FormControl,
  FormLabel,
  useToast,
  InputGroup,
  InputRightElement,
  Spinner,
  Flex,
  Box,
  Text,
  IconButton,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import Image from 'next/image'
import React, { useRef, useState } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'
import { isAddress } from 'viem'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useEnsAddress,
} from 'wagmi'

import { useDebounce } from '@/hooks/useDebounce'
import { shadowAbi } from 'abi/Shadow'
import { config } from 'config'

import { IBulkSelectToken, IOnSelectBulkTokens } from './types'

interface ISendToLoginDialogue {
  selectedTokens: IBulkSelectToken | null
  isOpen: boolean
  onClose: () => void
  onSelectBulkTokens: (selectedToken: IOnSelectBulkTokens) => void
}

const checkValidEns = (address: string) => {
  return address?.split('.')?.at(-1) === 'eth'
}

export const BulkSendToLoginDialogue = (props: ISendToLoginDialogue) => {
  const cancelRef = useRef(null)
  const [loginWalletAddress, setLoginWalletAddress] = useState<string>('')

  const isValidAddress = isAddress(loginWalletAddress)

  const debouncedEnsName = useDebounce(loginWalletAddress, 500)

  const { data: ensResolvedAddress, isLoading: ensResolveLoading } = useEnsAddress({
    name: debouncedEnsName,
    enabled: checkValidEns(loginWalletAddress),
  })

  const addRecentTransaction = useAddRecentTransaction()
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const { config: setSurragateConfig } = usePrepareContractWrite({
    address: props.selectedTokens?.FPTAddress,
    abi: shadowAbi as any,
    functionName: 'setSurrogates',
    chainId: config.chainId,
    args: [
      props.selectedTokens?.tokens.map((token) => token.tokenId),
      new Array(props.selectedTokens?.tokens.length).fill(
        ensResolvedAddress ? ensResolvedAddress : loginWalletAddress
      ),
    ],
    enabled:
      Boolean(props.selectedTokens?.tokens.length) &&
      props.isOpen &&
      (isValidAddress || Boolean(ensResolvedAddress)),

    onError(err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const {
    data: setSurragateData,
    isLoading: isSetSurrogateLoading,
    write: writeSetSurragate,
  } = useContractWrite({
    ...setSurragateConfig,
    onSuccess(data) {
      props.onClose()
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Send token to login wallet',
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSuccess: txnSuccess } = useWaitForTransaction({
    hash: setSurragateData?.hash,
    onSuccess() {
      toast({
        title: 'Transaction success',
        status: 'success',
      })
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLoginWalletAddress(event.target.value)

  const isDisabled = !(
    writeSetSurragate && (isValidAddress ? !!loginWalletAddress : !!ensResolvedAddress)
  )

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        colorScheme="slate"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={props.selectedTokens?.status === 'vulnerable' && props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Send to Holding Wallet</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody color="slate.500" mt="-5" fontSize="sm">
            Please enter your holding wallet address below
          </AlertDialogBody>
          <AlertDialogBody my="2">
            <FormControl>
              <FormLabel fontSize="sm" color="slate.500">
                Holding wallet
              </FormLabel>
              <InputGroup>
                <Input type="text" value={loginWalletAddress} onChange={handleChange} />
                <InputRightElement>
                  {ensResolveLoading ? (
                    <Spinner />
                  ) : isValidAddress || ensResolvedAddress ? (
                    <CheckIcon color="green.500" />
                  ) : null}
                </InputRightElement>
              </InputGroup>
              {!isValidAddress && loginWalletAddress !== '' && !ensResolvedAddress && (
                <FormHelperText color="red">Enter a valid address</FormHelperText>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogBody>
            <Text fontWeight="medium" mb="4" pt="4" borderTopWidth={1}>
              Selected Tokens
            </Text>
            {props.selectedTokens?.tokens.map(({ tokenId, parsedURI }) => (
              <Flex alignItems="center" justifyContent="space-between" key={tokenId} mb="2">
                <Flex alignItems="center">
                  <Box pos="relative" h="10" w="10" mr="4" overflow="hidden" rounded="lg">
                    <Image src={parsedURI} alt="" style={{ objectFit: 'cover' }} fill unoptimized />
                  </Box>
                  <Text fontSize="sm" fontWeight="medium">
                    {props.selectedTokens?.collectionName} #{tokenId}
                  </Text>
                </Flex>
                <IconButton
                  onClick={() =>
                    props.onSelectBulkTokens({
                      tokenId,
                      parsedURI,
                      status: 'vulnerable',
                      collection: props.selectedTokens?.collection as `0x${string}`,
                      FPTAddress: props.selectedTokens?.FPTAddress as `0x${string}`,
                      collectionName: props.selectedTokens?.collectionName as string,
                    })
                  }
                  variant="ghost"
                  aria-label="Search database"
                  icon={<RiCloseCircleLine size="22" />}
                />
              </Flex>
            ))}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isSetSurrogateLoading}
              isDisabled={isDisabled}
              loadingText="Waiting for approval"
              onClick={() => writeSetSurragate?.()}
              ml={3}
            >
              Transfer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

// export const SendToLoginDialogueMemo = React.memo(SendToLoginDialogue)
