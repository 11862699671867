import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  IconButton,
  useToast,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import Image from 'next/image'
import { useRef } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { shadowAbi } from 'abi/Shadow'
import { config } from 'config'

import { IBulkSelectToken, IOnSelectBulkTokens } from './types'

interface IRetrieveDialogue {
  selectedTokens: IBulkSelectToken | null
  isOpen: boolean
  onClose: () => void
  onSelectBulkTokens: (selectedToken: IOnSelectBulkTokens) => void
}

export const BulkRetrieveDialogue = (props: IRetrieveDialogue) => {
  const cancelRef = useRef(null)
  const addRecentTransaction = useAddRecentTransaction()

  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
    containerStyle: {
      marginTop: 20,
    },
  })

  const { config: unsetSurrogateConfig } = usePrepareContractWrite({
    address: props.selectedTokens?.FPTAddress,
    abi: shadowAbi as any,
    chainId: config.chainId,
    functionName: 'unsetSurrogates',
    enabled: Boolean(props.selectedTokens?.tokens.length) && props.isOpen,
    args: [props.selectedTokens?.tokens.map((token) => token.tokenId)],

    onError(err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
      })
    },
  })

  const {
    data: unsetSurragateData,
    isLoading: isUnsetSurrogateLoading,
    write: writeUnsetSurrogate,
  } = useContractWrite({
    ...unsetSurrogateConfig,
    onSuccess(data) {
      props.onClose()
      toast({
        title: 'Transaction submitted',
        description: 'Check account for details',
      })
      addRecentTransaction({
        hash: data.hash,
        description: 'Retrieve token',
      })
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSuccess } = useWaitForTransaction({
    hash: unsetSurragateData?.hash,
    onSuccess() {
      toast({
        title: 'Transaction success',
        status: 'success',
      })
    },
  })

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={props.selectedTokens?.status === 'secure' && props.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Retrieve tokens</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody color="slate.500" mt="-5" fontSize="sm">
            Retrieve tokens to current wallet
          </AlertDialogBody>
          <AlertDialogBody>
            <Text fontWeight="medium" mb="4" pt="4" borderTopWidth={1}>
              Selected Tokens
            </Text>
            {props.selectedTokens?.tokens.map(({ tokenId, parsedURI }) => (
              <Flex alignItems="center" justifyContent="space-between" key={tokenId} mb="3">
                <Flex alignItems="center">
                  <Box pos="relative" h="10" w="10" mr="4" overflow="hidden" rounded="lg">
                    <Image src={parsedURI} alt="" style={{ objectFit: 'cover' }} fill unoptimized />
                  </Box>
                  <Text fontSize="sm" fontWeight="medium">
                    {props.selectedTokens?.collectionName} #{tokenId}
                  </Text>
                </Flex>
                <IconButton
                  onClick={() =>
                    props.onSelectBulkTokens({
                      tokenId,
                      parsedURI,
                      status: 'secure',
                      collection: props.selectedTokens?.collection as `0x${string}`,
                      FPTAddress: props.selectedTokens?.FPTAddress as `0x${string}`,
                      collectionName: props.selectedTokens?.collectionName as string,
                    })
                  }
                  variant="ghost"
                  aria-label="Search database"
                  icon={<RiCloseCircleLine size="22" />}
                />
              </Flex>
            ))}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isUnsetSurrogateLoading}
              isDisabled={!writeUnsetSurrogate}
              loadingText="Waiting for approval"
              onClick={() => writeUnsetSurrogate?.()}
              colorScheme="brand"
              ml={3}
            >
              Transfer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
