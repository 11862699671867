import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, Heading, Text, useColorMode, Link as ChakraLink } from '@chakra-ui/react'
import Link from 'next/link'
import Balancer from 'react-wrap-balancer'

import { ConnectButton } from '@/components/layout/ConnectButton'

export const ConnectWallet = () => {
  const { colorMode } = useColorMode()

  return (
    <Flex
      alignItems={'center'}
      flexDirection="column"
      gap={'6'}
      justifyContent={'center'}
      p="6"
      textAlign="center"
      w="full"
      maxW="3xl"
      mx="auto"
      h="100vh"
      mt="-24"
    >
      <Text
        color={`${colorMode === 'light' ? 'brand.800' : 'brand.100'}`}
        fontSize="sm"
        px="4"
        py="2"
        mb="8"
        maxW="fit-content"
        rounded="md"
        w="sm"
        bg={`${colorMode === 'light' ? 'brand.100' : 'slate.800'}`}
      >
        <Balancer>
          Are you representing a project? Deploy FoolProof Tokens on your collection{' '}
          <ChakraLink
            isExternal
            target="_blank"
            rel="noreferrer"
            textDecoration="underline"
            colorScheme="brand"
            as={Link}
            href="http://foolprooflabs.io/deploy"
          >
            Go to Deploy tool
            <ExternalLinkIcon mx="2px" />
          </ChakraLink>
        </Balancer>
      </Text>

      <Heading fontSize="5xl">
        <Balancer>FoolProof Token Dashboard</Balancer>
      </Heading>
      <Text fontSize="md" color="slate.600">
        <Balancer>
          FoolProof Token (FPT) offers unparalleled security and control over digital assets by
          creating a 1:1 linked token to prove ownership without risking ownership.
        </Balancer>
      </Text>

      <Flex mt="6">
        <ConnectButton />
        {/* <Button colorScheme="slate" ml="6" variant="ghost">
            Learn more
          </Button> */}
      </Flex>
    </Flex>
  )
}
