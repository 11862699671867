import { CloseIcon, ExternalLinkIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Badge,
  useColorModeValue,
  Stack,
  useColorMode,
  IconButton,
  Text,
  Link as ChakraLink,
  UseDisclosureReturn,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useCallback } from 'react'
import { RiMoonFill, RiSunLine, RiGasStationFill } from 'react-icons/ri'
import { useAccount, useFeeData } from 'wagmi'

import { useIsClient } from '@/hooks/useIsClient'

import { IncrementCounter } from '..'
import FPLogo from '../../../public/foolproof-logo.svg'

import { ConnectButton } from './ConnectButton'

interface HeaderProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const Header = (props: HeaderProps) => {
  const account = useAccount()
  const { colorMode, toggleColorMode } = useColorMode()
  const isClient = useIsClient()

  const { data: ethGasPrice } = useFeeData({ formatUnits: 'gwei' })

  const incrementCounterState: UseDisclosureReturn = useDisclosure()

  const handleIncrementCounter = useCallback(() => {
    if (!(account.address && account.isConnected)) {
      alert("Please 'Connect Account' and try again")
      return
    }

    incrementCounterState.onOpen()
  }, [account, incrementCounterState])

  return (
    <>
      <Box
        bg={useColorModeValue('rgba(241, 245, 249,0.8)', 'rgba(15, 23, 42,0.8)')}
        backdropFilter="blur(8px)"
        borderBottomWidth={'1px'}
        borderColor={useColorModeValue('slate.200', 'slate.700')}
        px={{ base: 4, md: 12 }}
        position="sticky"
        top={0}
        zIndex={20}
      >
        <IncrementCounter {...incrementCounterState} />

        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
        >
          <IconButton
            size={'md'}
            icon={props.isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            mr="2"
            onClick={props.isOpen ? props.onClose : props.onOpen}
          />
          <Flex alignItems="center" gap={3}>
            <Link href="/">
              <Flex alignItems="center" gap={3}>
                <FPLogo fill={useColorModeValue('black', 'white')} width="160px" />
                <Badge colorScheme="orange"></Badge>
              </Flex>
            </Link>
          </Flex>

          <Flex alignItems={'center'} display={{ base: 'none', md: 'flex' }}>
            <Stack direction={'row'} alignItems="center" spacing={7}>
              <Flex alignItems="center" gap={3}>
                <Button onClick={handleIncrementCounter}>Increment Counter</Button>
              </Flex>

              <Flex alignItems="center">
                <RiGasStationFill />
                <Text ml="1" fontSize="sm">
                  {ethGasPrice &&
                    isClient &&
                    Math.round(parseFloat(ethGasPrice.formatted.gasPrice ?? '0'))}
                  <span style={{ fontSize: '0.75rem' }}>gwei</span>
                </Text>
              </Flex>
              <IconButton
                aria-label="theme toggle"
                variant="ghost"
                icon={colorMode === 'light' ? <RiMoonFill /> : <RiSunLine />}
                onClick={toggleColorMode}
              />
              <ConnectButton />
              <ChakraLink
                colorScheme="solid"
                isExternal
                target="_blank"
                rel="noreferrer"
                fontWeight="bold"
                as={Link}
                href="http://foolprooflabs.io/deploy"
              >
                Deploy tool
                <ExternalLinkIcon mx="4px" mb="4px" />
              </ChakraLink>
              {/* <Link colorScheme="solid" variant="link">
                Deploy Tool <ExternalLinkIcon ml="4px" />
              </Link> */}
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default Header
