import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  useColorMode,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  IconButton,
  BoxProps,
  FlexProps,
} from '@chakra-ui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactText } from 'react'
import { IconType } from 'react-icons'
import { RiMoonFill, RiSunLine } from 'react-icons/ri'

import FPLogo from '../../../public/foolproof-logo.svg'

import { ConnectButton } from './ConnectButton'

interface LinkItemProps {
  name: string
  icon: IconType
  href: string
}

interface SidebarProps {
  isOpen: boolean
  onClose: () => void
}
const LinkItems: Array<LinkItemProps> = [
  // { name: 'ShadowToken', icon: FiHome, href: '/' },
  // { name: 'Activity', icon: FiTrendingUp, href: '/activity' },
  // { name: 'Settings', icon: FiSettings, href: '/settings' },
]

export default function Sidebar(props: SidebarProps) {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')}>
      {/* <SidebarContent onClose={props.onClose} display={{ base: 'none', md: 'block' }} /> */}
      <Drawer
        autoFocus={false}
        isOpen={props.isOpen}
        placement="left"
        onClose={props.onClose}
        returnFocusOnClose={false}
        onOverlayClick={props.onClose}
        size="xs"
      >
        <DrawerContent>
          <DrawerCloseButton />
          <MobileSidebarContent onClose={props.onClose} />
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

interface SidebarContentProps extends BoxProps {
  onClose: () => void
}

// const SidebarContent = ({ ...rest }: SidebarContentProps) => {
//   return (
//     <Box
//       bg={useColorModeValue('white', 'gray.900')}
//       borderRightColor={useColorModeValue('gray.200', 'gray.700')}
//       p={7}
//       h="full"
//       {...rest}
//     >
//       {LinkItems.map((link) => (
//         <NavItem key={link.name} icon={link.icon} href={link.href}>
//           {link.name}
//         </NavItem>
//       ))}
//     </Box>
//   )
// }

const MobileSidebarContent = ({ onClose }: SidebarContentProps) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <>
      <DrawerHeader>
        {' '}
        <FPLogo fill={useColorModeValue('black', 'white')} width="130px" />
      </DrawerHeader>
      <DrawerBody>
        <Box>
          {LinkItems.map((link) => (
            <NavItem onClick={onClose} key={link.name} icon={link.icon} href={link.href}>
              {link.name}
            </NavItem>
          ))}
        </Box>
      </DrawerBody>

      <DrawerFooter>
        <ConnectButton />
        <IconButton
          aria-label="theme toggle"
          ml="3"
          icon={colorMode === 'light' ? <RiMoonFill /> : <RiSunLine />}
          onClick={toggleColorMode}
        />
      </DrawerFooter>
    </>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  children: ReactText
  href: string
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
  const router = useRouter()
  const colormodeVal = useColorModeValue('gray.300', 'gray.700')
  return (
    <Link href={href} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        px="4"
        py="2"
        my="3"
        borderRadius="md"
        role="group"
        cursor="pointer"
        bgColor={router.asPath === href ? colormodeVal : ''}
        _hover={{
          bg: useColorModeValue('gray.100', 'gray.800'),
        }}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {children}
      </Flex>
    </Link>
  )
}
